<template>
    <a @click="handleNavigation" :href="hrefPlaceholder">
        <slot></slot>
    </a>
</template>

<script lang="ts">

export interface IAsyncLinkProps {
    /** Async function that returns the link string */
    linkFn: () => Promise<string> | PromiseLike<string>;
    href?: string;
    target?: string;
};
</script>

<script setup lang="ts">
import { ref, computed } from 'vue';
const props = withDefaults(defineProps<IAsyncLinkProps>(), {
    href: '#',
});

const generatingLink = ref(false);

const hrefPlaceholder = computed(() => generatingLink.value ? null : props.href);
async function handleNavigation(e: MouseEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();
    generatingLink.value = true;
    try {
        const url = await props.linkFn();
        let parsedUrl: string = ''; 
        if (url.startsWith('/')) {
            parsedUrl = window.location.origin + url;
        } else if (!url.startsWith('http')) {
            parsedUrl = window.location.origin + window.location.pathname + url;
        } else {    
            parsedUrl = url;
        }
        if (props.target === '_blank') {
            window.open(parsedUrl);
        } else {
            window.location.href = parsedUrl;
        }

    } catch (ex) {
        import('o365.controls.alert.ts').then((alert) => {
            alert.default(ex?.message ?? ex, 'danger');
        });
    } finally {
        generatingLink.value = false;
    }
}
</script>